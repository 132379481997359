<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM8.8302 4.6647C8.8302 5.15882 8.43903 5.55685 7.93805 5.55685C7.44393 5.55685 7.04589 5.15882 7.04589 4.6647C7.04589 4.16372 7.44393 3.76568 7.93805 3.76568C8.43903 3.76568 8.8302 4.16372 8.8302 4.6647ZM10.0929 11.2941C10.0929 11.5755 9.87334 11.7745 9.59197 11.7745H6.79883C6.51746 11.7745 6.29785 11.5755 6.29785 11.2941C6.29785 11.0265 6.51746 10.8137 6.79883 10.8137H7.64981V7.66372H6.9155C6.63413 7.66372 6.41452 7.4647 6.41452 7.18332C6.41452 6.91568 6.63413 6.70293 6.9155 6.70293H8.2057C8.5557 6.70293 8.74099 6.94999 8.74099 7.32058V10.8137H9.59197C9.87334 10.8137 10.0929 11.0265 10.0929 11.2941Z"
      fill="black"
      fill-opacity="0.32"
    />
  </svg>
</template>

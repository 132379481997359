<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M75.3827 33.6757L43.3826 65.6757L27.3826 49.6757"
        stroke="#E6007A"
        stroke-width="6"
      />
      <rect x="16" y="13" width="32" height="35" fill="white" />
      <circle cx="50" cy="50" r="47" stroke="#E6007A" stroke-width="6" />
    </g>
  </svg>
</template>

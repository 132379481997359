<!-- eslint-disable prettier/prettier -->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
  >
    <ellipse
      id="zko92f32pgp0d50h7kl5oosm"
      opacity="0.1"
      stroke="#E6007A"
      stroke-width="6"
      rx="47"
      ry="47"
      transform="translate(50,50)"
    />
    <ellipse
      id="rb9mgm42cst1dsdfn19ud57k"
      opacity="1"
      stroke="#E6007A"
      stroke-width="6"
      rx="47"
      ry="47"
      stroke-dasharray="90 300"
      stroke-dashoffset="0"
      transform="translate(50,50)"
      style="animation: 1s linear infinite both rb9mgm42cst1dsdfn19ud57k_t, 1s linear infinite both rb9mgm42cst1dsdfn19ud57k_do"
    />
  </svg>
</template>
<style>
@keyframes rb9mgm42cst1dsdfn19ud57k_t {
  0% {
    transform: translate(50px, 50px) rotate(0deg);
  }
  100% {
    transform: translate(50px, 50px) rotate(360deg);
  }
}
@keyframes rb9mgm42cst1dsdfn19ud57k_do {
  0% {
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dashoffset: 50px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}
</style>

<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.435974 2.18404C0 3.03968 0 4.15979 0 6.4V11.6C0 13.8402 0 14.9603 0.435974 15.816C0.819467 16.5686 1.43139 17.1805 2.18404 17.564C3.03968 18 4.15979 18 6.4 18H11.6C13.8402 18 14.9603 18 15.816 17.564C16.5686 17.1805 17.1805 16.5686 17.564 15.816C18 14.9603 18 13.8402 18 11.6V6.4C18 4.15979 18 3.03968 17.564 2.18404C17.1805 1.43139 16.5686 0.819467 15.816 0.435974C14.9603 0 13.8402 0 11.6 0H6.4C4.15979 0 3.03968 0 2.18404 0.435974C1.43139 0.819467 0.819467 1.43139 0.435974 2.18404ZM14.2809 6.12471C14.6259 5.69345 14.556 5.06415 14.1247 4.71914C13.6935 4.37413 13.0642 4.44405 12.7191 4.8753L7.47339 11.4324L5.25927 8.84922C4.89986 8.42989 4.26856 8.38132 3.84923 8.74074C3.4299 9.10016 3.38133 9.73146 3.74075 10.1508L6.74067 13.6508C6.93451 13.8769 7.21906 14.0049 7.51687 13.9999C7.81468 13.9948 8.09474 13.8573 8.2808 13.6247L14.2809 6.12471Z"
      fill="#E6007A"
    />
  </svg>
</template>

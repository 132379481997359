<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9984 8.00259L11.9984 8.0026L4.24065 20.9322C2.6468 23.5886 1.84988 24.9168 2.12699 25.9963C2.24432 26.4534 2.48085 26.8711 2.81243 27.2069C3.59546 27.9999 5.1444 27.9999 8.24228 27.9999H23.7578C26.8557 27.9999 28.4047 27.9999 29.1877 27.2069C29.5193 26.8711 29.7558 26.4534 29.8731 25.9963C30.1502 24.9168 29.3533 23.5886 27.7595 20.9322L20.0017 8.0026C18.4862 5.47685 17.7285 4.21398 16.6794 3.93759C16.2341 3.82028 15.766 3.82028 15.3207 3.93759C14.2716 4.21398 13.5139 5.47685 11.9984 8.00259ZM17.2269 13.2267C17.2269 12.5492 16.6776 11.9999 16.0001 11.9999C15.9661 11.9999 15.9321 12.0013 15.8982 12.0041C15.2229 12.0604 14.7212 12.6534 14.7775 13.3286L15.278 19.3355C15.3093 19.711 15.6232 19.9999 16.0001 19.9999C16.3769 19.9999 16.6908 19.711 16.7221 19.3355L17.2227 13.3286C17.2255 13.2947 17.2269 13.2607 17.2269 13.2267ZM17.3334 22.6665C17.3334 23.4029 16.7364 23.9999 16.0001 23.9999C15.2637 23.9999 14.6667 23.4029 14.6667 22.6665C14.6667 21.9302 15.2637 21.3332 16.0001 21.3332C16.7364 21.3332 17.3334 21.9302 17.3334 22.6665Z"
      fill="#FF3B30"
    />
  </svg>
</template>

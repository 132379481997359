export const chainLogosMap: Record<string, string> = {
  "Ajuna Testnet": require("@/assets/pic/logos/nodes/ajuna.png"),
  "Aleph Zero": require("@/assets/pic/logos/chains/aleph.svg"),
  "Aleph Zero Testnet": require("@/assets/pic/logos/chains/aleph.svg"),
  Altair: require("@/assets/pic/logos/chains/altair.svg"),
  Amplitude: require("@/assets/pic/logos/chains/amplitude.svg"),
  "Apron PC1": require("@/assets/pic/logos/nodes/apron.png"),
  Arctic: require("@/assets/pic/logos/nodes/arctic.png"),
  "Ares Gladios": require("@/assets/pic/logos/nodes/ares-gladios.svg"),
  "Ares Odyssey": require("@/assets/pic/logos/nodes/ares-odyssey.svg"),
  "Ares PC1": require("@/assets/pic/logos/nodes/ares.png"),
  Astar: require("@/assets/pic/logos/nodes/astar.png"),
  Automata: require("@/assets/pic/logos/nodes/automata.png"),
  "Automata ContextFree": require("@/assets/pic/logos/nodes/automata.png"),
  Aventus: require("@/assets/pic/logos/nodes/aventus.svg"),
  "Bajun Testnet": require("@/assets/pic/logos/nodes/bajun.png"),
  "Beast Developer": require("@/assets/pic/logos/nodes/beast.svg"),
  Bifrost: require("@/assets/pic/logos/nodes/bifrost.svg"),
  "Bifrost Asgard CC4": require("@/assets/pic/logos/nodes/bifrost.svg"),
  "Bifrost K Rococo": require("@/assets/pic/logos/nodes/bifrost.svg"),
  "Bifrost Kusama": require("@/assets/pic/logos/nodes/bifrost.svg"),
  "Bifrost Polkadot": require("@/assets/pic/logos/nodes/bifrost.svg"),
  "Bifrost Stage Testnet": require("@/assets/pic/logos/nodes/bifrost.svg"),
  Bitgreen: require("@/assets/pic/logos/chains/bitgreen.png"),
  BitgreenRococo: require("@/assets/pic/logos/chains/bitgreen.png"),
  "Calamari Parachain": require("@/assets/pic/logos/nodes/calamari.png"),
  "Calamari Parachain Development": require("@/assets/pic/logos/nodes/calamari.png"),
  "Calamari Parachain Local": require("@/assets/pic/logos/nodes/calamari.png"),
  "Calamari Parachain Testnet": require("@/assets/pic/logos/nodes/calamari.png"),
  "Catalyst Testnet": require("@/assets/pic/logos/nodes/centrifuge.png"),
  Centrifuge: require("@/assets/pic/logos/nodes/centrifuge.png"),
  "CESS Testnet": require("@/assets/pic/logos/nodes/cess.png"),
  ChainOLI: require("@/assets/pic/logos/nodes/oli.svg"),
  ChainX: require("@/assets/pic/logos/nodes/chainx.svg"),
  "Charcoal Testnet": require("@/assets/pic/logos/nodes/centrifuge.png"),
  Coinversation: require("@/assets/pic/logos/nodes/coinversation.png"),
  "Competitors Club": require("@/assets/pic/logos/nodes/competitors-club.png"),
  "Composable Finance": require("@/assets/pic/logos/chains/composableFinance.png"),
  "Contracts on Rococo": require("@/assets/pic/logos/chains/rococo-contracts.png"),
  "Crab Parachain": require("@/assets/pic/logos/nodes/crab.svg"),
  Creditcoin: require("@/assets/pic/logos/chains/creditcoin.png"),
  "Creditcoin Testnet": require("@/assets/pic/logos/chains/creditcoin-test.png"),
  "Crown Sterling": require("@/assets/pic/logos/nodes/crown-sterling.png"),
  "Crust Maxwell": require("@/assets/pic/logos/nodes/crust-maxwell.svg"),
  "Crust PC1": require("@/assets/pic/logos/nodes/crust.svg"),
  "Crust Testnet": require("@/assets/pic/logos/nodes/crustParachain.svg"),
  "darwinia crab": require("@/assets/pic/logos/nodes/crab.svg"),
  "Darwinia Crab PC2": require("@/assets/pic/logos/nodes/crab.svg"),
  "Darwinia Parachain": require("@/assets/pic/logos/nodes/darwinia.svg"),
  "Darwinia PC2": require("@/assets/pic/logos/nodes/darwinia.svg"),
  DataHighway: require("@/assets/pic/logos/nodes/datahighway.png"),
  "DataHighway Spreehafen Rococo Parachain Testnet": require("@/assets/pic/logos/chains/datahighway.png"),
  "DataHighway Tanganika Kusama Parachain": require("@/assets/pic/logos/chains/tanganika.png"),
  "Dolphin Testnet": require("@/assets/pic/logos/nodes/dolphin.svg"),
  "Dora Factory": require("@/assets/pic/logos/chains/dorafactory.png"),
  Efinity: require("@/assets/pic/logos/nodes/efinity.svg"),
  "Egg Rococo": require("@/assets/pic/logos/chains/webb.png"),
  Equilibrium: require("@/assets/pic/logos/chains/equilibrium.svg"),
  "Equilibrium parachain": require("@/assets/pic/logos/chains/equilibrium.svg"),
  EquilibriumTestnet: require("@/assets/pic/logos/chains/equilibrium.svg"),
  Galital: require("@/assets/pic/logos/nodes/galital-logo.png"),
  "GamePower Network": require("@/assets/pic/logos/nodes/gamepower.svg"),
  GEEK: require("@/assets/pic/logos/nodes/geek.svg"),
  Geminis: require("@/assets/pic/logos/chains/geminis.png"),
  Genshiro: require("@/assets/pic/logos/chains/genshiro.svg"),
  "Genshiro Rococo Testnet": require("@/assets/pic/logos/chains/genshiro.svg"),
  "GM Parachain": require("@/assets/pic/logos/chains/gm.png"),
  helixstreet: require("@/assets/pic/logos/nodes/helixstreet.png"),
  HydraDX: require("@/assets/pic/logos/chains/snakenet.svg"),
  "HydraDX testnet": require("@/assets/pic/logos/chains/hydrate.png"),
  Idavoll: require("@/assets/pic/logos/nodes/idavoll.png"),
  "Imbue Testnet": require("@/assets/pic/logos/nodes/imbue.png"),
  InterBTC: require("@/assets/pic/logos/nodes/interlay.svg"),
  "InterBTC Staging": require("@/assets/pic/logos/nodes/interlay.svg"),
  Interlay: require("@/assets/pic/logos/chains/interlay.svg"),
  "InvArch Brainstorm Testnet": require("@/assets/pic/logos/chains/brainstorm.png"),
  "InvArch Tinker Network": require("@/assets/pic/logos/chains/tinker.png"),
  ipci: require("@/assets/pic/logos/nodes/ipci.svg"),
  IpseTestnet: require("@/assets/pic/logos/nodes/ipse.png"),
  "Jupiter A1": require("@/assets/pic/logos/nodes/jupiter.svg"),
  "Jupiter PC1": require("@/assets/pic/logos/nodes/jupiter.svg"),
  Kabocha: require("@/assets/pic/logos/nodes/kabocha.svg"),
  "Kabocha (kabsoup)": require("@/assets/pic/logos/nodes/kabocha.svg"),
  Kapex: require("@/assets/pic/logos/chains/totem.svg"),
  Karura: require("@/assets/pic/logos/chains/karura.svg"),
  Kerria: require("@/assets/pic/logos/nodes/parallel.svg"),
  "Kerria Dev": require("@/assets/pic/logos/nodes/parallel.svg"),
  Khala: require("@/assets/pic/logos/nodes/khala.svg"),
  KICO: require("@/assets/pic/logos/chains/kico.png"),
  KILT: require("@/assets/pic/logos/nodes/kilt.png"),
  "KILT Local": require("@/assets/pic/logos/nodes/kilt.png"),
  "KILT Peregrine": require("@/assets/pic/logos/nodes/kilt.png"),
  "KILT Testnet": require("@/assets/pic/logos/nodes/kilt.png"),
  Kintsugi: require("@/assets/pic/logos/chains/kintsugi.png"),
  KlugDossier: require("@/assets/pic/logos/nodes/klug.png"),
  Konomi: require("@/assets/pic/logos/nodes/konomi.png"),
  Kpron: require("@/assets/pic/logos/nodes/apron.png"),
  Kusama: require("@/assets/pic/logos/chains/kusama-128.gif"),
  "Kusama CC1": require("@/assets/pic/logos/chains/kusama-128.gif"),
  "Kusama CC2": require("@/assets/pic/logos/chains/kusama-128.gif"),
  "Kusama CC3": require("@/assets/pic/logos/chains/kusama-128.gif"),
  kusari: require("@/assets/pic/logos/nodes/kusari.svg"),
  "Kylin Testnet": require("@/assets/pic/logos/nodes/kylin.png"),
  "Listen Network": require("@/assets/pic/logos/chains/listen.png"),
  Litentry: require("@/assets/pic/logos/nodes/litentry.png"),
  "Litentry-rococo": require("@/assets/pic/logos/nodes/litentry.png"),
  Litmus: require("@/assets/pic/logos/nodes/litmus.png"),
  "Loom Network Local": require("@/assets/pic/logos/nodes/loom_network.png"),
  LoomNetwork: require("@/assets/pic/logos/nodes/loom_network.png"),
  "Mangata Kusama Mainnet": require("@/assets/pic/logos/chains/mangatax.svg"),
  "Mangata Public Testnet": require("@/assets/pic/logos/chains/mangatax.svg"),
  "Manta Parachain": require("@/assets/pic/logos/nodes/manta.png"),
  "Manta Parachain Development": require("@/assets/pic/logos/nodes/manta.png"),
  "Manta Parachain Local": require("@/assets/pic/logos/nodes/manta.png"),
  "Manta Parachain Testnet": require("@/assets/pic/logos/nodes/manta.png"),
  Mars: require("@/assets/pic/logos/nodes/ares-mars.png"),
  MathChain: require("@/assets/pic/logos/nodes/math.svg"),
  "MathChain PC1": require("@/assets/pic/logos/nodes/math.svg"),
  "mathchain-galois": require("@/assets/pic/logos/nodes/math.svg"),
  MD5: require("@/assets/pic/logos/nodes/md5.png"),
  Minix: require("@/assets/pic/logos/nodes/minix.png"),
  "Minix Testnet": require("@/assets/pic/logos/nodes/minix.png"),
  "Moonbase Alpha": require("@/assets/pic/logos/nodes/moonbase_alpha.png"),
  "Moonbase Development Testnet": require("@/assets/pic/logos/nodes/moonbase_alpha.png"),
  "Moonbase Stage": require("@/assets/pic/logos/nodes/moonbase_alpha.png"),
  Moonbeam: require("@/assets/pic/logos/nodes/moonbeam.png"),
  Moonriver: require("@/assets/pic/logos/nodes/moonriver.svg"),
  Moonrock: require("@/assets/pic/logos/nodes/moonrock.png"),
  "Moonsama Development": require("@/assets/pic/logos/chains/moonsama.png"),
  Moonshadow: require("@/assets/pic/logos/nodes/moonshadow.png"),
  "mybank.network PC1": require("@/assets/pic/logos/nodes/mybank.png"),
  Myriad: require("@/assets/pic/logos/chains/myriad.svg"),
  NFTMart: require("@/assets/pic/logos/nodes/nftmart.png"),
  "NFTMart Staging": require("@/assets/pic/logos/nodes/nftmart.png"),
  "NFTMart Testnet": require("@/assets/pic/logos/nodes/nftmart.png"),
  "OAK Network": require("@/assets/pic/logos/chains/oak.png"),
  Odyssey: require("@/assets/pic/logos/nodes/ares-odyssey.svg"),
  "OLI Parachain": require("@/assets/pic/logos/chains/oli.svg"),
  OmniBTC: require("@/assets/pic/logos/nodes/omnibtc.svg"),
  "OPAL by UNIQUE": require("@/assets/pic/logos/chains/opal-logo.png"),
  "OriginTrail Parachain": require("@/assets/pic/logos/chains/origintrail.png"),
  "OriginTrail Parachain Testnet": require("@/assets/pic/logos/chains/origintrail-testnet.png"),
  Pangolin: require("@/assets/pic/logos/nodes/pangolin.svg"),
  Pangoro: require("@/assets/pic/logos/nodes/pangoro.svg"),
  Parallel: require("@/assets/pic/logos/nodes/parallel.svg"),
  "Parallel Dev": require("@/assets/pic/logos/nodes/parallel.svg"),
  "Parallel Heiko": require("@/assets/pic/logos/nodes/parallel.svg"),
  "Parallel Heiko Dev": require("@/assets/pic/logos/nodes/parallel.svg"),
  "Parami PC2": require("@/assets/pic/logos/nodes/parami.png"),
  Phala: require("@/assets/pic/logos/nodes/phala.svg"),
  "PHOENIX PC1": require("@/assets/pic/logos/nodes/phoenix.png"),
  Picasso: require("@/assets/pic/logos/chains/picasso.svg"),
  "Pichiu Mainnet": require("@/assets/pic/logos/nodes/pichiu.png"),
  "Pichiu Testnet": require("@/assets/pic/logos/nodes/pichiu.png"),
  "Pioneer Network": require("@/assets/pic/logos/nodes/pioneer.png"),
  "Polkadex Mainnet": require("@/assets/pic/logos/nodes/polkadex.svg"),
  "Polkadex Testnet": require("@/assets/pic/logos/nodes/polkadex.svg"),
  "PolkaFoundry PC1": require("@/assets/pic/logos/nodes/polkafoundry.svg"),
  "Pontem Testnet": require("@/assets/pic/logos/nodes/pontem.svg"),
  "Prism PC1": require("@/assets/pic/logos/nodes/prism.png"),
  "Prism Testnet": require("@/assets/pic/logos/nodes/prism.png"),
  "QUARTZ by UNIQUE": require("@/assets/pic/logos/chains/quartz.png"),
  "ReAlis Network": require("@/assets/pic/logos/nodes/realis.png"),
  "RioChain CC-1": require("@/assets/pic/logos/nodes/riochain.svg"),
  "RioChain Staging": require("@/assets/pic/logos/nodes/riochain.svg"),
  Robonomics: require("@/assets/pic/logos/nodes/robonomics.svg"),
  Rocfinity: require("@/assets/pic/logos/nodes/rocfinity.svg"),
  Rococo: require("@/assets/pic/logos/chains/rococo.svg"),
  Sherpax: require("@/assets/pic/logos/nodes/sherpax.png"),
  "Sherpax Testnet": require("@/assets/pic/logos/nodes/sherpax.png"),
  Shiden: require("@/assets/pic/logos/chains/shiden.png"),
  SingLavender: require("@/assets/pic/logos/nodes/singlavender.svg"),
  SkyeKiwi: require("@/assets/pic/logos/chains/skyekiwi.png"),
  "Snow Kusama": require("@/assets/pic/logos/nodes/snow.png"),
  soonsocial: require("@/assets/pic/logos/nodes/soonsocial.png"),
  soonsocialX: require("@/assets/pic/logos/nodes/soonsocialX.png"),
  SORA: require("@/assets/pic/logos/nodes/sora-substrate.svg"),
  "SORA Kusama": require("@/assets/pic/logos/nodes/sora-substrate.svg"),
  "SORA Rococo": require("@/assets/pic/logos/nodes/sora-substrate.svg"),
  Spanner: require("@/assets/pic/logos/chains/spanner.png"),
  Stagex: require("@/assets/pic/logos/chains/totem.svg"),
  Statemine: require("@/assets/pic/logos/nodes/statemine.svg"),
  "Statemine Test": require("@/assets/pic/logos/nodes/statemine.svg"),
  "Statemint Test": require("@/assets/pic/logos/nodes/statemine.svg"),
  "Steam PC": require("@/assets/pic/logos/nodes/eave.svg"),
  subdao: require("@/assets/pic/logos/nodes/subdao.png"),
  "SubDAO PC1": require("@/assets/pic/logos/nodes/subdao.png"),
  "SubDAO Staging": require("@/assets/pic/logos/nodes/subdao.png"),
  subgame: require("@/assets/pic/logos/nodes/subgame.svg"),
  "SubGame Gamma": require("@/assets/pic/logos/nodes/subgame.svg"),
  "SubGame Staging": require("@/assets/pic/logos/nodes/subgame.svg"),
  subsocial: require("@/assets/pic/logos/nodes/subsocial.svg"),
  subsocialX: require("@/assets/pic/logos/nodes/subsocialX.svg"),
  subspace: require("@/assets/pic/logos/nodes/subspace.png"),
  Subzero: require("@/assets/pic/logos/nodes/zero.svg"),
  swapdex: require("@/assets/pic/logos/nodes/swapdex.svg"),
  t0rn: require("@/assets/pic/logos/chains/t0rn.png"),
  Tick: require("@/assets/pic/logos/chains/rococo-tick.svg"),
  Track: require("@/assets/pic/logos/chains/rococo-track.svg"),
  Trick: require("@/assets/pic/logos/chains/rococo-trick.svg"),
  trustbase: require("@/assets/pic/logos/nodes/trustbase.png"),
  "TrustBase PC1": require("@/assets/pic/logos/nodes/trustbase.png"),
  turing: require("@/assets/pic/logos/chains/turing.png"),
  "uni arts staging network": require("@/assets/pic/logos/nodes/uniarts.png"),
  "UniArts Mainnet": require("@/assets/pic/logos/nodes/uniarts.png"),
  Unique: require("@/assets/pic/logos/chains/unique.svg"),
  UNIQUE: require("@/assets/pic/logos/chains/unique.svg"),
  "Unit Network": require("@/assets/pic/logos/nodes/unitv.png"),
  Unorthodox: require("@/assets/pic/logos/chains/unorthodox.png"),
  VirtoRococo: require("@/assets/pic/logos/chains/virto.png"),
  Vln: require("@/assets/pic/logos/nodes/valiu.png"),
  "VLN PC": require("@/assets/pic/logos/nodes/valiu.png"),
  Wapex: require("@/assets/pic/logos/chains/totem.svg"),
  "Watr Network": require("@/assets/pic/logos/chains/watr.png"),
  "Web3Games Plum": require("@/assets/pic/logos/nodes/web3games.svg"),
  Westend: require("@/assets/pic/logos/nodes/westend_colour.svg"),
  "Westend Collectives": require("@/assets/pic/logos/chains/westend-collectives.png"),
  Westlake: require("@/assets/pic/logos/nodes/westlake.png"),
  Westmint: require("@/assets/pic/logos/nodes/statemine.svg"),
  "Westmint Test": require("@/assets/pic/logos/nodes/statemine.svg"),
  WILT: require("@/assets/pic/logos/nodes/kilt.png"),
  "zcloak poc1": require("@/assets/pic/logos/nodes/zCloak.svg"),
  "Acala Node": require("@/assets/pic/logos/chains/acala.svg"),
  "Ajuna Node": require("@/assets/pic/logos/nodes/ajuna.png"),
  "Apron Node": require("@/assets/pic/logos/nodes/apron.png"),
  "Apron Parachain Collator": require("@/assets/pic/logos/nodes/apron.png"),
  "Ares Node": require("@/assets/pic/logos/nodes/ares.png"),
  "Ares Parachain Collator": require("@/assets/pic/logos/nodes/ares.png"),
  "Automata ContextFree Node": require("@/assets/pic/logos/nodes/automata.png"),
  "Automata Node": require("@/assets/pic/logos/nodes/automata.png"),
  "Bajun Node": require("@/assets/pic/logos/nodes/bajun.png"),
  Basilisk: require("@/assets/pic/logos/nodes/basilisk.png"),
  "Beast Node": require("@/assets/pic/logos/nodes/beast.svg"),
  "Bifrost Node": require("@/assets/pic/logos/nodes/bifrost.svg"),
  "Bit Country Tewai Parachain Collator": require("@/assets/pic/logos/nodes/bitcountry.png"),
  "Bit.Country": require("@/assets/pic/logos/nodes/bitcountry.png"),
  "BitCountry Node": require("@/assets/pic/logos/nodes/bitcountry.png"),
  "Calamari Parachain Collator": require("@/assets/pic/logos/nodes/calamari.png"),
  "centrifuge chain": require("@/assets/pic/logos/nodes/centrifuge.png"),
  "Centrifuge Chain Node": require("@/assets/pic/logos/nodes/centrifuge.png"),
  "ChainX Node": require("@/assets/pic/logos/nodes/chainx.svg"),
  "Clover Node": require("@/assets/pic/logos/nodes/clover.svg"),
  crust: require("@/assets/pic/logos/nodes/crust.svg"),
  "Crust Collator": require("@/assets/pic/logos/nodes/crust.svg"),
  "DAO IPCI": require("@/assets/pic/logos/nodes/ipci.svg"),
  darwinia: require("@/assets/pic/logos/nodes/darwinia.svg"),
  "darwinia parachain": require("@/assets/pic/logos/nodes/darwinia.svg"),
  "Darwinia Runtime Module Library": require("@/assets/pic/logos/nodes/darwinia.svg"),
  "DataHighway Node": require("@/assets/pic/logos/nodes/datahighway.png"),
  "DataHighway Parachain Collator": require("@/assets/pic/logos/nodes/datahighway.png"),
  "Dock Full Node": require("@/assets/pic/logos/nodes/dock-mainnet.png"),
  "Dock Node": require("@/assets/pic/logos/nodes/dock-mainnet.png"),
  "DOTMog Node": require("@/assets/pic/logos/nodes/dotmog.svg"),
  "Eave Node": require("@/assets/pic/logos/nodes/eave.svg"),
  "Edgeware Node": require("@/assets/pic/logos/nodes/edgeware-white.png"),
  "Efinity Node": require("@/assets/pic/logos/nodes/efinity.svg"),
  "Encointer collator": require("@/assets/pic/logos/nodes/encointer-blue.svg"),
  "Encointer Node noTEE": require("@/assets/pic/logos/nodes/encointer-blue.svg"),
  "Fantour Node": require("@/assets/pic/logos/nodes/fantour.png"),
  "Galital Parachain Collator": require("@/assets/pic/logos/nodes/galital-logo.png"),
  "GamePower Node": require("@/assets/pic/logos/nodes/gamepower.svg"),
  "GM Collator": require("@/assets/pic/logos/chains/gm.png"),
  "Halongbay Parachain Collator": require("@/assets/pic/logos/nodes/polkafoundry.svg"),
  hanonycash: require("@/assets/pic/logos/nodes/hanonycash.svg"),
  Helixstreet: require("@/assets/pic/logos/nodes/helixstreet.png"),
  "Idavoll Node": require("@/assets/pic/logos/nodes/idavoll.png"),
  "Imbue Node": require("@/assets/pic/logos/nodes/imbue.png"),
  "Integritee Collator": require("@/assets/pic/logos/nodes/integritee.svg"),
  "Integritee Node": require("@/assets/pic/logos/nodes/integritee.svg"),
  "InvArch Brainstorm Node": require("@/assets/pic/logos/chains/brainstorm.png"),
  "InvArch Tinkernet Node": require("@/assets/pic/logos/chains/tinker.png"),
  "Jaz Node": require("@/assets/pic/logos/nodes/jaz.png"),
  "Joystream Node": require("@/assets/pic/logos/nodes/joystream.svg"),
  "Khala Node": require("@/assets/pic/logos/nodes/khala.svg"),
  "Klug Dossier Node": require("@/assets/pic/logos/nodes/klug.png"),
  "Kpron Collator": require("@/assets/pic/logos/nodes/apron.png"),
  kulupu: require("@/assets/pic/logos/nodes/kulupu.svg"),
  "Kylin Node": require("@/assets/pic/logos/nodes/kylin.png"),
  "Laminar Node": require("@/assets/pic/logos/nodes/laminar-circle.svg"),
  "Litentry node": require("@/assets/pic/logos/nodes/litentry.png"),
  "logion Collator": require("@/assets/pic/logos/chains/logion.png"),
  "Logion Node": require("@/assets/pic/logos/chains/logion.png"),
  "mandala node": require("@/assets/pic/logos/chains/acala.svg"),
  "Manta Node": require("@/assets/pic/logos/nodes/manta.png"),
  "Manta Parachain Collator": require("@/assets/pic/logos/nodes/manta.png"),
  "mathChain-galois": require("@/assets/pic/logos/nodes/math.svg"),
  "MD5 Network": require("@/assets/pic/logos/nodes/md5.png"),
  minix: require("@/assets/pic/logos/nodes/minix.png"),
  "mybank.network": require("@/assets/pic/logos/nodes/mybank.png"),
  "node-template": require("@/assets/pic/logos/nodes/substrate-hexagon.svg"),
  "Nodle Chain Node": require("@/assets/pic/logos/nodes/nodle.svg"),
  "Opal Node": require("@/assets/pic/logos/nodes/opal-logo.png"),
  "Opportunity Standalone Testnet": require("@/assets/pic/logos/nodes/opportunity.png"),
  Parami: require("@/assets/pic/logos/nodes/parami.png"),
  "parity-polkadot": require("@/assets/pic/logos/nodes/polkadot-circle.svg"),
  "Patract Node": require("@/assets/pic/logos/nodes/jupiter.svg"),
  phala: require("@/assets/pic/logos/nodes/phala.svg"),
  "Phala Collator": require("@/assets/pic/logos/nodes/phala.svg"),
  "phala-substrate-node": require("@/assets/pic/logos/nodes/phala.svg"),
  "PHOENIX Collator": require("@/assets/pic/logos/nodes/phoenix.png"),
  "PHOENIX Node": require("@/assets/pic/logos/nodes/phoenix.png"),
  "Pichiu Node": require("@/assets/pic/logos/nodes/pichiu.png"),
  "Pioneer Network Collator Node": require("@/assets/pic/logos/nodes/pioneer.png"),
  "Polkadex Node": require("@/assets/pic/logos/nodes/polkadex.svg"),
  "polkadot-js": require("@/assets/pic/logos/nodes/polkadot-js.svg"),
  "PolkaFoundry Node": require("@/assets/pic/logos/nodes/polkafoundry.svg"),
  "PolkaFoundry Parachain Collator": require("@/assets/pic/logos/nodes/polkafoundry.svg"),
  "PolkaSmith Parachain Collator": require("@/assets/pic/logos/nodes/polkasmith.svg"),
  "Prism Collator": require("@/assets/pic/logos/nodes/prism.png"),
  "Prism Node": require("@/assets/pic/logos/nodes/prism.png"),
  "Quartz Node": require("@/assets/pic/logos/nodes/quartz.png"),
  "Rio Defi Chain Node": require("@/assets/pic/logos/nodes/riochain.svg"),
  robonomics: require("@/assets/pic/logos/nodes/robonomics.svg"),
  Sakura: require("@/assets/pic/logos/nodes/sakura.svg"),
  Shadow: require("@/assets/pic/logos/nodes/shadow.svg"),
  sherpax: require("@/assets/pic/logos/nodes/sherpax.png"),
  "Shiden Collator": require("@/assets/pic/logos/chains/shiden.png"),
  "SingLavender Parachain Collator": require("@/assets/pic/logos/nodes/singlavender.svg"),
  Snow: require("@/assets/pic/logos/nodes/snow.png"),
  Sora: require("@/assets/pic/logos/nodes/sora-substrate.svg"),
  Stafi: require("@/assets/pic/logos/nodes/stafi.png"),
  "Stafi Node": require("@/assets/pic/logos/nodes/stafi.png"),
  "Statemine Collator": require("@/assets/pic/logos/nodes/statemine.svg"),
  "Statemint Collator": require("@/assets/pic/logos/nodes/statemine.svg"),
  "SubDAO Collator": require("@/assets/pic/logos/nodes/subdao.png"),
  "substrate-contracts-node": require("@/assets/pic/logos/nodes/substrate-contracts-node.png"),
  "substrate-node": require("@/assets/pic/logos/nodes/substrate-hexagon.svg"),
  "subzero node": require("@/assets/pic/logos/nodes/zero.svg"),
  "Ternoa Node": require("@/assets/pic/logos/nodes/ternoa.svg"),
  "Totem Parachain Collator": require("@/assets/pic/logos/chains/totem.svg"),
  "TrustBase Collator": require("@/assets/pic/logos/nodes/trustbase.png"),
  "TrustBase Node": require("@/assets/pic/logos/nodes/trustbase.png"),
  "uni arts node": require("@/assets/pic/logos/nodes/uniarts.png"),
  "UniArts Node": require("@/assets/pic/logos/nodes/uniarts.png"),
  "Unique Node": require("@/assets/pic/logos/nodes/unique.svg"),
  "Unit Collator": require("@/assets/pic/logos/nodes/unitv.png"),
  "Unit Node": require("@/assets/pic/logos/nodes/unitv.png"),
  Vanilla: require("@/assets/pic/logos/nodes/parallel.svg"),
  "Vanilla Dev": require("@/assets/pic/logos/nodes/parallel.svg"),
  "Web3Games Node": require("@/assets/pic/logos/nodes/web3games.svg"),
  "Westmint Collator": require("@/assets/pic/logos/nodes/statemine.svg"),
  Whala: require("@/assets/pic/logos/nodes/whala.svg"),
  "Whala Node": require("@/assets/pic/logos/nodes/whala.svg"),
  "zcloak node": require("@/assets/pic/logos/nodes/zCloak.svg"),
  "Zeitgeist Collator": require("@/assets/pic/logos/nodes/zeitgeist.png"),
  "Zeitgeist Node": require("@/assets/pic/logos/nodes/zeitgeist.png"),
  Zenlink: require("@/assets/pic/logos/nodes/zenlink.svg"),
  "Zenlink Collator": require("@/assets/pic/logos/nodes/zenlink.svg"),
  acala: require("@/assets/pic/logos/chains/acala.svg"),
  ajuna: require("@/assets/pic/logos/nodes/ajuna.png"),
  aleph: require("@/assets/pic/logos/chains/aleph.svg"),
  alexander: require("@/assets/pic/logos/nodes/polkadot-circle.svg"),
  altair: require("@/assets/pic/logos/chains/altair.svg"),
  amplitude: require("@/assets/pic/logos/chains/amplitude.svg"),
  arctic: require("@/assets/pic/logos/nodes/arctic.png"),
  astar: require("@/assets/pic/logos/chains/astar.png"),
  automata: require("@/assets/pic/logos/nodes/automata.png"),
  "automata-contextfree": require("@/assets/pic/logos/nodes/automata.png"),
  aventus: require("@/assets/pic/logos/chains/aventus.svg"),
  bajun: require("@/assets/pic/logos/nodes/bajun.png"),
  basilisk: require("@/assets/pic/logos/nodes/basilisk.png"),
  beast: require("@/assets/pic/logos/nodes/beast.svg"),
  bifrost: require("@/assets/pic/logos/nodes/bifrost.svg"),
  bitcountry: require("@/assets/pic/logos/nodes/bitcountry.png"),
  bitcountryPioneer: require("@/assets/pic/logos/nodes/pioneer.png"),
  bitgreen: require("@/assets/pic/logos/chains/bitgreen.png"),
  brainstorm: require("@/assets/pic/logos/chains/brainstorm.png"),
  calamari: require("@/assets/pic/logos/nodes/calamari.png"),
  centrifuge: require("@/assets/pic/logos/nodes/centrifuge.png"),
  cess: require("@/assets/pic/logos/nodes/cess.png"),
  chainoli: require("@/assets/pic/logos/nodes/oli.svg"),
  chainx: require("@/assets/pic/logos/nodes/chainx.svg"),
  charcoal: require("@/assets/pic/logos/nodes/centrifuge.png"),
  clover: require("@/assets/pic/logos/nodes/clover.svg"),
  coinversation: require("@/assets/pic/logos/chains/coinversation.png"),
  "competitors-club": require("@/assets/pic/logos/chains/competitors-club.png"),
  composableFinance: require("@/assets/pic/logos/chains/composableFinance.png"),
  crab: require("@/assets/pic/logos/nodes/crab.svg"),
  creditcoin: require("@/assets/pic/logos/chains/creditcoin.png"),
  "creditcoin-testnet": require("@/assets/pic/logos/chains/creditcoin-test.png"),
  "crown-sterling": require("@/assets/pic/logos/chains/crown-sterling.png"),
  crustParachain: require("@/assets/pic/logos/nodes/crustParachain.svg"),
  "Dali Testnet (Rococo Relay)": require("@/assets/pic/logos/chains/dali.png"),
  DaliTestnet: require("@/assets/pic/logos/nodes/dali.png"),
  datahighway: require("@/assets/pic/logos/nodes/datahighway.png"),
  "dock-pos-mainnet": require("@/assets/pic/logos/nodes/dock-mainnet.png"),
  "dock-pos-testnet": require("@/assets/pic/logos/nodes/dock-testnet.png"),
  dolphin: require("@/assets/pic/logos/nodes/dolphin.svg"),
  dorafactory: require("@/assets/pic/logos/chains/dorafactory.png"),
  dotmog: require("@/assets/pic/logos/nodes/dotmog.svg"),
  eave: require("@/assets/pic/logos/nodes/eave.svg"),
  edgeware: require("@/assets/pic/logos/nodes/edgeware-white.png"),
  efinity: require("@/assets/pic/logos/nodes/efinity.svg"),
  Eggnet: require("@/assets/pic/logos/chains/webb.png"),
  empty: require("@/assets/pic/logos/nodes/polkadot-circle.svg"),
  encointer: require("@/assets/pic/logos/nodes/encointer-blue.svg"),
  equilibrium: require("@/assets/pic/logos/chains/equilibrium.svg"),
  fantour: require("@/assets/pic/logos/nodes/fantour.png"),
  galital: require("@/assets/pic/logos/nodes/galital-logo.png"),
  galois: require("@/assets/pic/logos/nodes/math.svg"),
  gamepower: require("@/assets/pic/logos/nodes/gamepower.svg"),
  geek: require("@/assets/pic/logos/nodes/geek.svg"),
  geminis: require("@/assets/pic/logos/chains/geminis.png"),
  genshiro: require("@/assets/pic/logos/chains/genshiro.svg"),
  gm: require("@/assets/pic/logos/chains/gm.png"),
  halongbay: require("@/assets/pic/logos/nodes/polkafoundry.svg"),
  heiko: require("@/assets/pic/logos/chains/parallel.svg"),
  hydra: require("@/assets/pic/logos/chains/snakenet.svg"),
  idavoll: require("@/assets/pic/logos/nodes/idavoll.png"),
  imbue: require("@/assets/pic/logos/nodes/imbue.png"),
  integritee: require("@/assets/pic/logos/nodes/integritee.svg"),
  interlay: require("@/assets/pic/logos/chains/interlay.svg"),
  "interlay-testnet": require("@/assets/pic/logos/chains/interlay.svg"),
  ipse: require("@/assets/pic/logos/nodes/ipse.png"),
  jaz: require("@/assets/pic/logos/nodes/jaz.png"),
  joystream: require("@/assets/pic/logos/nodes/joystream.svg"),
  jupiter: require("@/assets/pic/logos/nodes/jupiter.svg"),
  kabocha: require("@/assets/pic/logos/nodes/kabocha.svg"),
  kapex: require("@/assets/pic/logos/chains/totem.svg"),
  karura: require("@/assets/pic/logos/chains/karura.svg"),
  kerria: require("@/assets/pic/logos/nodes/parallel.svg"),
  khala: require("@/assets/pic/logos/nodes/khala.svg"),
  kico: require("@/assets/pic/logos/chains/kico.png"),
  kilt: require("@/assets/pic/logos/nodes/kilt.png"),
  kintsugi: require("@/assets/pic/logos/chains/kintsugi.png"),
  "kintsugi-testnet": require("@/assets/pic/logos/chains/kintsugi.png"),
  klugdossier: require("@/assets/pic/logos/nodes/klug.png"),
  kpron: require("@/assets/pic/logos/nodes/apron.png"),
  kusama: require("@/assets/pic/logos/chains/kusama-128.gif"),
  kylin: require("@/assets/pic/logos/nodes/kylin.png"),
  laminar: require("@/assets/pic/logos/nodes/laminar-circle.svg"),
  listen: require("@/assets/pic/logos/chains/listen.png"),
  litentry: require("@/assets/pic/logos/nodes/litentry.png"),
  litmus: require("@/assets/pic/logos/nodes/litmus.png"),
  logion: require("@/assets/pic/logos/chains/logion.png"),
  loomNetwork: require("@/assets/pic/logos/nodes/loom_network.png"),
  mangata: require("@/assets/pic/logos/chains/mangatax.svg"),
  manta: require("@/assets/pic/logos/nodes/manta.png"),
  mars: require("@/assets/pic/logos/nodes/ares-mars.png"),
  mathchain: require("@/assets/pic/logos/nodes/math.svg"),
  moonbaseAlpha: require("@/assets/pic/logos/nodes/moonbase_alpha.png"),
  moonbeam: require("@/assets/pic/logos/nodes/moonbeam.png"),
  moonriver: require("@/assets/pic/logos/nodes/moonriver.svg"),
  moonrock: require("@/assets/pic/logos/nodes/moonrock.png"),
  moonshadow: require("@/assets/pic/logos/nodes/moonshadow.png"),
  mybank: require("@/assets/pic/logos/nodes/mybank.png"),
  myriad: require("@/assets/pic/logos/chains/myriad.svg"),
  nftmart: require("@/assets/pic/logos/nodes/nftmart.png"),
  nodle: require("@/assets/pic/logos/nodes/nodle.svg"),
  oak: require("@/assets/pic/logos/chains/oak.png"),
  odyssey: require("@/assets/pic/logos/nodes/ares-odyssey.svg"),
  omnibtc: require("@/assets/pic/logos/nodes/omnibtc.svg"),
  opal: require("@/assets/pic/logos/nodes/opal-logo.png"),
  opportunity: require("@/assets/pic/logos/nodes/opportunity.png"),
  "origintrail-parachain": require("@/assets/pic/logos/chains/origintrail.png"),
  pangolin: require("@/assets/pic/logos/nodes/pangolin.svg"),
  pangoro: require("@/assets/pic/logos/nodes/pangoro.svg"),
  parallel: require("@/assets/pic/logos/chains/parallel.svg"),
  phoenix: require("@/assets/pic/logos/nodes/phoenix.png"),
  picasso: require("@/assets/pic/logos/chains/picasso.svg"),
  pichiu: require("@/assets/pic/logos/nodes/pichiu.png"),
  polkadex: require("@/assets/pic/logos/nodes/polkadex.svg"),
  polkadot: require("@/assets/pic/logos/nodes/polkadot-circle.svg"),
  polkafoundry: require("@/assets/pic/logos/nodes/polkafoundry.svg"),
  polkasmith: require("@/assets/pic/logos/nodes/polkasmith.svg"),
  polymesh: require("@/assets/pic/logos/nodes/polymesh.svg"),
  pontem: require("@/assets/pic/logos/nodes/pontem.svg"),
  prism: require("@/assets/pic/logos/nodes/prism.png"),
  quartz: require("@/assets/pic/logos/nodes/quartz.png"),
  realis: require("@/assets/pic/logos/nodes/realis.png"),
  riochain: require("@/assets/pic/logos/nodes/riochain.svg"),
  rocfinity: require("@/assets/pic/logos/nodes/rocfinity.svg"),
  rocky: require("@/assets/pic/logos/nodes/crust.svg"),
  rococo: require("@/assets/pic/logos/chains/rococo.svg"),
  rococoAcala: require("@/assets/pic/logos/chains/acala.svg"),
  rococoAmplitude: require("@/assets/pic/logos/chains/amplitude.svg"),
  rococoApron: require("@/assets/pic/logos/nodes/apron.png"),
  rococoAres: require("@/assets/pic/logos/nodes/ares.png"),
  rococoAventus: require("@/assets/pic/logos/nodes/aventus.svg"),
  rococoBajun: require("@/assets/pic/logos/nodes/bajun.png"),
  rococoBasilisk: require("@/assets/pic/logos/nodes/basilisk.png"),
  rococoBifrost: require("@/assets/pic/logos/nodes/bifrost.svg"),
  rococoBitCountry: require("@/assets/pic/logos/nodes/bitcountry.png"),
  rococoBitgreen: require("@/assets/pic/logos/chains/bitgreen.png"),
  rococoCatalyst: require("@/assets/pic/logos/nodes/centrifuge.png"),
  rococoChainX: require("@/assets/pic/logos/nodes/chainx.svg"),
  rococoClover: require("@/assets/pic/logos/nodes/clover.svg"),
  rococoConfti: require("@/assets/pic/logos/nodes/confti.svg"),
  rococoContracts: require("@/assets/pic/logos/chains/rococo-contracts.png"),
  rococoCrab: require("@/assets/pic/logos/nodes/crab.svg"),
  rococoCrust: require("@/assets/pic/logos/nodes/crust.svg"),
  rococoDali: require("@/assets/pic/logos/chains/dali.png"),
  rococoDarwinia: require("@/assets/pic/logos/nodes/darwinia.svg"),
  rococoDolphin: require("@/assets/pic/logos/nodes/dolphin.svg"),
  rococoEave: require("@/assets/pic/logos/nodes/eave.svg"),
  rococoEncointer: require("@/assets/pic/logos/nodes/encointer-blue.svg"),
  rococoFrequency: require("@/assets/pic/logos/nodes/frequencyRococo.png"),
  rococoGalital: require("@/assets/pic/logos/nodes/galital-logo.png"),
  rococoGenshiro: require("@/assets/pic/logos/chains/genshiro.svg"),
  rococoHelixstreet: require("@/assets/pic/logos/nodes/helixstreet.png"),
  rococoHydraDX: require("@/assets/pic/logos/chains/hydrate.png"),
  rococoIdavoll: require("@/assets/pic/logos/nodes/idavoll.png"),
  rococoImbue: require("@/assets/pic/logos/nodes/imbue.png"),
  rococoIntegritee: require("@/assets/pic/logos/nodes/integritee.svg"),
  rococoInterBTC: require("@/assets/pic/logos/chains/interlay.svg"),
  rococoJupiter: require("@/assets/pic/logos/nodes/jupiter.svg"),
  rococoKabocha: require("@/assets/pic/logos/nodes/kabocha.svg"),
  rococoKilt: require("@/assets/pic/logos/nodes/kilt.png"),
  rococoKonomi: require("@/assets/pic/logos/nodes/konomi.png"),
  rococoKylin: require("@/assets/pic/logos/nodes/kylin.png"),
  rococoLaminar: require("@/assets/pic/logos/nodes/laminar-circle.svg"),
  rococoLitentry: require("@/assets/pic/logos/nodes/litentry.png"),
  rococoLoomNetwork: require("@/assets/pic/logos/nodes/loom_network.png"),
  rococoMangata: require("@/assets/pic/logos/chains/mangatax.svg"),
  rococoManta: require("@/assets/pic/logos/nodes/manta.png"),
  rococoMathChain: require("@/assets/pic/logos/nodes/math.svg"),
  rococoMd5: require("@/assets/pic/logos/nodes/md5.png"),
  rococoMoonrock: require("@/assets/pic/logos/nodes/moonrock.png"),
  rococoMoonsama: require("@/assets/pic/logos/chains/moonsama.png"),
  rococoNodle: require("@/assets/pic/logos/nodes/nodle.svg"),
  rococoOriginTrailParachain: require("@/assets/pic/logos/chains/origintrail-testnet.png"),
  rococoPangolin: require("@/assets/pic/logos/nodes/pangolin.svg"),
  rococoParami: require("@/assets/pic/logos/nodes/parami.png"),
  rococoPhala: require("@/assets/pic/logos/nodes/phala.svg"),
  rococoPhoenix: require("@/assets/pic/logos/nodes/phoenix.png"),
  rococoPolkaFoundry: require("@/assets/pic/logos/nodes/polkafoundry.svg"),
  rococoPrism: require("@/assets/pic/logos/nodes/prism.png"),
  rococoSingLavender: require("@/assets/pic/logos/nodes/singlavender.svg"),
  rococoSora: require("@/assets/pic/logos/nodes/sora-substrate.svg"),
  rococoSpreehafen: require("@/assets/pic/logos/chains/datahighway.png"),
  rococoStandard: require("@/assets/pic/logos/chains/standard.png"),
  rococoStatemint: require("@/assets/pic/logos/nodes/statemine.svg"),
  rococoSubDAO: require("@/assets/pic/logos/nodes/subdao.png"),
  rococoSubsocial: require("@/assets/pic/logos/nodes/soonsocialX.png"),
  rococoSubzero: require("@/assets/pic/logos/nodes/zero.svg"),
  rococoTick: require("@/assets/pic/logos/chains/rococo-tick.svg"),
  rococoTinkernet: require("@/assets/pic/logos/chains/tinker.png"),
  rococoTrack: require("@/assets/pic/logos/chains/rococo-track.svg"),
  rococoTrick: require("@/assets/pic/logos/chains/rococo-trick.svg"),
  rococoTrustBase: require("@/assets/pic/logos/nodes/trustbase.png"),
  rococoTuring: require("@/assets/pic/logos/chains/turing.png"),
  rococoUnitv: require("@/assets/pic/logos/nodes/unitv.png"),
  rococoVirto: require("@/assets/pic/logos/chains/virto.png"),
  rococoVln: require("@/assets/pic/logos/nodes/valiu.png"),
  rococoWatr: require("@/assets/pic/logos/chains/watr.png"),
  rococoZeitgeist: require("@/assets/pic/logos/nodes/zeitgeist.png"),
  rococoZenlink: require("@/assets/pic/logos/nodes/zenlink.svg"),
  sakura: require("@/assets/pic/logos/nodes/sakura.svg"),
  shadow: require("@/assets/pic/logos/nodes/shadow.svg"),
  shell: require("@/assets/pic/logos/nodes/shell.svg"),
  shibuya: require("@/assets/pic/logos/chains/shiden.png"),
  shiden: require("@/assets/pic/logos/chains/shiden.png"),
  singLavender: require("@/assets/pic/logos/nodes/singlavender.svg"),
  skyekiwi: require("@/assets/pic/logos/chains/skyekiwi.png"),
  snakenet: require("@/assets/pic/logos/chains/snakenet.svg"),
  snow: require("@/assets/pic/logos/nodes/snow.png"),
  snowbridge: require("@/assets/pic/logos/chains/snowbridge.png"),
  sora: require("@/assets/pic/logos/nodes/sora-substrate.svg"),
  "sora-substrate": require("@/assets/pic/logos/nodes/sora-substrate.svg"),
  sora_ksm: require("@/assets/pic/logos/nodes/sora-substrate.svg"),
  spanner: require("@/assets/pic/logos/chains/spanner.png"),
  stafi: require("@/assets/pic/logos/nodes/stafi.png"),
  stagex: require("@/assets/pic/logos/chains/totem.svg"),
  statemine: require("@/assets/pic/logos/nodes/statemine.svg"),
  statemint: require("@/assets/pic/logos/nodes/statemine.svg"),
  "subspace-farmnet": require("@/assets/pic/logos/nodes/subspace.png"),
  "subspace-gemini-1": require("@/assets/pic/logos/nodes/subspace.png"),
  "subspace-gemini-2a": require("@/assets/pic/logos/nodes/subspace.png"),
  substrate: require("@/assets/pic/logos/nodes/substrate-hexagon.svg"),
  substrateContractsNode: require("@/assets/pic/logos/nodes/substrate-contracts-node.png"),
  tanganika: require("@/assets/pic/logos/chains/tanganika.png"),
  ternoa: require("@/assets/pic/logos/nodes/ternoa.svg"),
  "ternoa-alphanet": require("@/assets/pic/logos/nodes/ternoa.svg"),
  "ternoa-testnet": require("@/assets/pic/logos/nodes/ternoa.svg"),
  tinker: require("@/assets/pic/logos/chains/tinker.png"),
  "totem-parachain": require("@/assets/pic/logos/chains/totem.svg"),
  uniarts: require("@/assets/pic/logos/nodes/uniarts.png"),
  unique: require("@/assets/pic/logos/nodes/unique.svg"),
  unitv: require("@/assets/pic/logos/nodes/unitv.png"),
  unorthodox: require("@/assets/pic/logos/chains/unorthodox.png"),
  vanilla: require("@/assets/pic/logos/nodes/parallel.svg"),
  vln: require("@/assets/pic/logos/nodes/valiu.png"),
  web3games: require("@/assets/pic/logos/nodes/web3games.svg"),
  westend: require("@/assets/pic/logos/nodes/westend_colour.svg"),
  westendCollectives: require("@/assets/pic/logos/chains/westend-collectives.png"),
  westendPichiu: require("@/assets/pic/logos/nodes/pichiu.png"),
  westendStandard: require("@/assets/pic/logos/chains/standard.png"),
  westlake: require("@/assets/pic/logos/nodes/westlake.png"),
  westmint: require("@/assets/pic/logos/nodes/statemine.svg"),
  whala: require("@/assets/pic/logos/nodes/whala.svg"),
  zCloak: require("@/assets/pic/logos/nodes/zCloak.svg"),
  zeitgeist: require("@/assets/pic/logos/nodes/zeitgeist.png"),
  zero: require("@/assets/pic/logos/nodes/zero.svg"),
};

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_264_5404)">
      <path
        d="M2.32698 3.63803C2 4.27976 2 5.11984 2 6.8V11.2C2 12.8802 2 13.7202 2.32698 14.362C2.6146 14.9265 3.07354 15.3854 3.63803 15.673C4.16873 15.9434 4.83507 15.9902 6 15.9983V14H5.6C5.03995 14 4.75992 14 4.54601 13.891C4.35785 13.7951 4.20487 13.6422 4.10899 13.454C4 13.2401 4 12.9601 4 12.4V5.6C4 5.03995 4 4.75992 4.10899 4.54601C4.20487 4.35785 4.35785 4.20487 4.54601 4.10899C4.75992 4 5.03995 4 5.6 4H12.4C12.9601 4 13.2401 4 13.454 4.10899C13.6422 4.20487 13.7951 4.35785 13.891 4.54601C14 4.75992 14 5.03995 14 5.6V6H15.9983C15.9902 4.83507 15.9434 4.16873 15.673 3.63803C15.3854 3.07354 14.9265 2.6146 14.362 2.32698C13.7202 2 12.8802 2 11.2 2H6.8C5.11984 2 4.27976 2 3.63803 2.32698C3.07354 2.6146 2.6146 3.07354 2.32698 3.63803Z"
        fill="black"
        fill-opacity="0.96"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 12.8C8 11.1198 8 10.2798 8.32698 9.63803C8.6146 9.07354 9.07354 8.6146 9.63803 8.32698C10.2798 8 11.1198 8 12.8 8H17.2C18.8802 8 19.7202 8 20.362 8.32698C20.9265 8.6146 21.3854 9.07354 21.673 9.63803C22 10.2798 22 11.1198 22 12.8V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C19.7202 22 18.8802 22 17.2 22H12.8C11.1198 22 10.2798 22 9.63803 21.673C9.07354 21.3854 8.6146 20.9265 8.32698 20.362C8 19.7202 8 18.8802 8 17.2V12.8ZM10 11.6C10 11.0399 10 10.7599 10.109 10.546C10.2049 10.3578 10.3578 10.2049 10.546 10.109C10.7599 10 11.0399 10 11.6 10H18.4C18.9601 10 19.2401 10 19.454 10.109C19.6422 10.2049 19.7951 10.3578 19.891 10.546C20 10.7599 20 11.0399 20 11.6V18.4C20 18.9601 20 19.2401 19.891 19.454C19.7951 19.6422 19.6422 19.7951 19.454 19.891C19.2401 20 18.9601 20 18.4 20H11.6C11.0399 20 10.7599 20 10.546 19.891C10.3578 19.7951 10.2049 19.6422 10.109 19.454C10 19.2401 10 18.9601 10 18.4V11.6Z"
        fill="black"
        fill-opacity="0.96"
      />
    </g>
    <defs>
      <clipPath id="clip0_264_5404">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M62.5 48C62.5 51.544 59.588 55.1564 53.9566 57.9721C48.4149 60.7429 40.6542 62.5 32 62.5C23.3458 62.5 15.5851 60.7429 10.0434 57.9721C4.412 55.1564 1.5 51.544 1.5 48C1.5 44.456 4.412 40.8436 10.0434 38.0279C15.5851 35.2571 23.3458 33.5 32 33.5C40.6542 33.5 48.4149 35.2571 53.9566 38.0279C59.588 40.8436 62.5 44.456 62.5 48Z"
      fill="white"
      stroke="#E6007A"
      stroke-width="3"
    />
    <path
      d="M62.5 38C62.5 41.544 59.588 45.1564 53.9566 47.9721C48.4149 50.7429 40.6542 52.5 32 52.5C23.3458 52.5 15.5851 50.7429 10.0434 47.9721C4.412 45.1564 1.5 41.544 1.5 38C1.5 34.456 4.412 30.8436 10.0434 28.0279C15.5851 25.2571 23.3458 23.5 32 23.5C40.6542 23.5 48.4149 25.2571 53.9566 28.0279C59.588 30.8436 62.5 34.456 62.5 38Z"
      fill="white"
      stroke="#E6007A"
      stroke-width="3"
    />
    <path
      d="M62.5 28C62.5 31.544 59.588 35.1564 53.9566 37.9721C48.4149 40.7429 40.6542 42.5 32 42.5C23.3458 42.5 15.5851 40.7429 10.0434 37.9721C4.412 35.1564 1.5 31.544 1.5 28C1.5 24.456 4.412 20.8436 10.0434 18.0279C15.5851 15.2571 23.3458 13.5 32 13.5C40.6542 13.5 48.4149 15.2571 53.9566 18.0279C59.588 20.8436 62.5 24.456 62.5 28Z"
      fill="white"
      stroke="#E6007A"
      stroke-width="3"
    />
    <path
      d="M62.5 18C62.5 21.544 59.588 25.1564 53.9566 27.9721C48.4149 30.7429 40.6542 32.5 32 32.5C23.3458 32.5 15.5851 30.7429 10.0434 27.9721C4.412 25.1564 1.5 21.544 1.5 18C1.5 14.456 4.412 10.8436 10.0434 8.02793C15.5851 5.25709 23.3458 3.5 32 3.5C40.6542 3.5 48.4149 5.25709 53.9566 8.02793C59.588 10.8436 62.5 14.456 62.5 18Z"
      fill="white"
      stroke="#E6007A"
      stroke-width="3"
    />
    <path
      d="M31.9995 11.1964C36.3773 11.1964 39.9262 10.257 39.9262 9.09818C39.9262 7.93939 36.3773 7 31.9995 7C27.6218 7 24.0729 7.93939 24.0729 9.09818C24.0729 10.257 27.6218 11.1964 31.9995 11.1964Z"
      fill="#E6007A"
    />
    <path
      d="M31.9995 27.0001C36.3773 27.0001 39.9262 26.0607 39.9262 24.9019C39.9262 23.7431 36.3773 22.8037 31.9995 22.8037C27.6218 22.8037 24.0729 23.7431 24.0729 24.9019C24.0729 26.0607 27.6218 27.0001 31.9995 27.0001Z"
      fill="#E6007A"
    />
    <path
      d="M20.9519 14.0974C23.1407 12.3727 23.1269 10.5048 20.9209 9.92543C18.7149 9.34603 15.1522 10.2745 12.9633 11.9992C10.7744 13.7239 10.7883 15.5918 12.9943 16.1712C15.2003 16.7506 18.763 15.8221 20.9519 14.0974Z"
      fill="#E6007A"
    />
    <path
      d="M51.0369 22.0007C53.2258 20.276 53.2119 18.4081 51.006 17.8287C48.8 17.2494 45.2373 18.1778 43.0484 19.9025C40.8595 21.6272 40.8734 23.4951 43.0794 24.0745C45.2853 24.6539 48.8481 23.7254 51.0369 22.0007Z"
      fill="#E6007A"
    />
    <path
      d="M20.9209 24.0746C23.1269 23.4952 23.1407 21.6273 20.9519 19.9026C18.763 18.1779 15.2003 17.2494 12.9943 17.8288C10.7883 18.4082 10.7744 20.2761 12.9633 22.0008C15.1522 23.7255 18.7149 24.654 20.9209 24.0746Z"
      fill="#E6007A"
    />
    <path
      d="M51.006 16.1713C53.2119 15.5919 53.2258 13.724 51.0369 11.9993C48.8481 10.2746 45.2853 9.34612 43.0794 9.92552C40.8734 10.5049 40.8595 12.3728 43.0484 14.0975C45.2373 15.8222 48.8 16.7506 51.006 16.1713Z"
      fill="#E6007A"
    />
  </svg>
</template>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
  >
    <ellipse
      id="s2uoco6gagpot3b70cpwze1q"
      stroke="#e6007a"
      stroke-width="6"
      rx="47"
      ry="47"
      opacity="0.1"
      transform="translate(50,50)"
      style="animation: 0.4s linear both s2uoco6gagpot3b70cpwze1q_o"
    />
    <path
      id="fh2oj7jwswve6zo62opbxp3l"
      d="M73.3827,33.6757L41.3826,65.6757L25.3826,49.6757"
      stroke="#E6007A"
      stroke-width="6"
      stroke-dasharray="67.89 100"
      stroke-dashoffset="-68"
      transform="translate(49.3826,49.6757) translate(-49.3826,-49.6757)"
      style="animation: 0.4s linear both fh2oj7jwswve6zo62opbxp3l_do"
    />
  </svg>
</template>

<style>
@keyframes s2uoco6gagpot3b70cpwze1q_o {
  0% {
    opacity: 0.1;
    animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  }
  100% {
    opacity: 1;
  }
}
@keyframes fh2oj7jwswve6zo62opbxp3l_do {
  0% {
    stroke-dashoffset: -68px;
    animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  }
  100% {
    stroke-dashoffset: 0px;
    animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  }
}
</style>

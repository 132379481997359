<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.25 9.05C13.25 10.5201 13.25 11.2552 12.9639 11.8167C12.7122 12.3107 12.3107 12.7122 11.8167 12.9639C11.2552 13.25 10.5201 13.25 9.05 13.25C8.2299 13.25 7.7701 13.25 6.95 13.25C5.47986 13.25 4.74479 13.25 4.18327 12.9639C3.68935 12.7122 3.28778 12.3107 3.03611 11.8167C2.75 11.2552 2.75 10.5201 2.75 9.05V6.95C2.75 5.47986 2.75 4.74479 3.03611 4.18327C3.28778 3.68935 3.68935 3.28778 4.18327 3.03611C4.74479 2.75 5.47986 2.75 6.95 2.75"
      stroke="black"
      stroke-opacity="0.56"
      stroke-width="1.5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.25 1H8.99999L8.99999 2.5L12.4393 2.5L6.96967 7.96967L8.03033 9.03033L13.5 3.56065L13.5 7L15 7L15 1.75V1H14.25Z"
      fill="black"
      fill-opacity="0.56"
    />
  </svg>
</template>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 12H6" stroke="black" stroke-opacity="0.96" stroke-width="2" />
    <path
      d="M12 5.00009L5.50001 11.5001L5.50001 12.5001L12 19.0001"
      stroke="black"
      stroke-opacity="0.96"
      stroke-width="2"
    />
  </svg>
</template>

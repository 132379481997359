<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.9001 12C4.9001 8.07878 8.07888 4.90001 12.0001 4.90001C15.9213 4.90001 19.1001 8.07878 19.1001 12C19.1001 15.9212 15.9213 19.1 12.0001 19.1C8.07888 19.1 4.9001 15.9212 4.9001 12ZM12.0001 3.10001C7.08476 3.10001 3.1001 7.08467 3.1001 12C3.1001 16.9153 7.08476 20.9 12.0001 20.9C16.9154 20.9 20.9001 16.9153 20.9001 12C20.9001 7.08467 16.9154 3.10001 12.0001 3.10001ZM9.8637 9.6364L12.2273 12L9.8637 14.3636L11.1365 15.6364L14.1365 12.6364L14.7729 12L14.1365 11.3636L11.1365 8.36361L9.8637 9.6364Z"
      fill="black"
      fill-opacity="0.56"
    />
  </svg>
</template>

<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5859 12.5001L5.29297 17.793L6.70718 19.2072L12.0001 13.9143L17.293 19.2072L18.7072 17.793L13.4143 12.5001L18.7072 7.20718L17.293 5.79297L12.0001 11.0859L6.70718 5.79297L5.29297 7.20718L10.5859 12.5001Z"
      fill="black"
      fill-opacity="0.96"
    />
  </svg>
</template>

<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.56666 7.99993C3.56666 5.55147 5.55153 3.5666 8 3.5666C10.4485 3.5666 12.4333 5.55147 12.4333 7.99993C12.4333 10.4484 10.4485 12.4333 8 12.4333C5.55153 12.4333 3.56666 10.4484 3.56666 7.99993ZM8 1.7666C4.55742 1.7666 1.76666 4.55736 1.76666 7.99993C1.76666 11.4425 4.55742 14.2333 8 14.2333C11.4426 14.2333 14.2333 11.4425 14.2333 7.99993C14.2333 4.55736 11.4426 1.7666 8 1.7666ZM6.3636 6.63633L7.7272 7.99994L6.3636 9.36354L7.63639 10.6363L9.63639 8.63633L10.2728 7.99994L9.63639 7.36354L7.63639 5.36354L6.3636 6.63633Z"
      fill="black"
    />
  </svg>
</template>

<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 7L15 12L10 17" stroke="black" stroke-width="2" />
  </svg>
</template>

<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_810_10581)">
      <circle
        cx="50"
        cy="50"
        r="47"
        stroke="#E6007A"
        stroke-width="6"
        style="animation: 0.4s linear both s2uoco6gagpot3b70cpwze1q_o"
      />
      <rect
        x="35.1504"
        y="33.0295"
        width="45"
        height="3"
        transform="rotate(45 35.1504 33.0295)"
        stroke="#E6007A"
        stroke-width="3"
        style="animation: 0.4s linear both s2uoco6gagpot3b70cpwze1q_o"
      />
      <rect
        x="66.9705"
        y="35.1504"
        width="45"
        height="3"
        transform="rotate(135 66.9705 35.1504)"
        stroke="#E6007A"
        stroke-width="3"
        style="animation: 0.4s linear both s2uoco6gagpot3b70cpwze1q_o"
      />
    </g>
  </svg>
</template>

<style>
@keyframes s2uoco6gagpot3b70cpwze1q_o {
  0% {
    opacity: 0.1;
    animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  }
  100% {
    opacity: 1;
  }
}
</style>
